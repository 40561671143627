import { CreateControllerFn, IHttpClient } from '@wix/yoshi-flow-editor';

const getAdaptiveContent = async (
  originalContent: string,
  $w: any,
  httpClient: IHttpClient,
) => {
  const { data: newContent } = await httpClient.post<any>(
    '/_serverless/whats-next-notifier/adaptiveContent',
    {
      original_content: originalContent,
    },
    {
      headers: {
        'X-Time-Budget': 10000,
      },
    },
  );
  $w('@text').text = newContent;
};

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
  appData,
}) => {
  const $w = controllerConfig.$w as any;

  return {
    pageReady: async () => {
      const originalContent = $w('@text').text;
      if (flowAPI.environment.isViewer) {
        return getAdaptiveContent(originalContent, $w, flowAPI.httpClient);
      }
    },
  };
};

export default createController;
